/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useMemo, useEffect, useContext } from 'react';
import {
  Box,
  FormHelperText,
  Grid,
} from '@mui/material';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { PlatformContext } from '@upptic/module-directory';
import { applicationCreativeConceptsGql } from '../gql';
import DataGrid from '../DataGrid/DataGrid';
import { Search } from './Search';
import Icon, { Icons } from '../../components/Icon';

export const ConceptSelectionInline = ({
  onSelect,
  selectedValue,
  error,
}) => {
  const platformStore = useContext(PlatformContext);
  const currentClientCode = platformStore?.currentClient?.code;
  const currentApplicationCode = platformStore?.selectedApplication?.code;
  const { t } = useTranslation('conceptsGrid');
  const { t: talt } = useTranslation('ui');
  const { t: tg } = useTranslation('general');
  const { t: tcg } = useTranslation('creativeGeneral');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [rowCount, setRowCount] = useState(0);
  const [selectionModel, setSelectionModel] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const filterToSend = useMemo(() => {
    if (searchTerm) {
      return ({
        $or: [
          { name: { $ct: searchTerm } },
          { internalId: { $ct: searchTerm } },
        ],
      });
    }
    return {};
  }, [searchTerm]);

  const {
    fetchMore,
    loading,
  } = useQuery(applicationCreativeConceptsGql, {
    onError: () => {},
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    context: {
      handleError: true,
    },
    variables: {
      clientCode: currentClientCode,
      applicationCode: currentApplicationCode,
      filter: filterToSend,
      offset: 0,
      limit: pageSize,
      sort: ['createDate:DESC'],
    },
    onCompleted: ({ applicationCreativeConcepts }) => {
      applicationCreativeConcepts.totalItemCount > 0 && setRowCount(applicationCreativeConcepts.totalItemCount);
      if (searchTerm) {
        setGridData(applicationCreativeConcepts?.items);
      }
    },
  });

  const onPageChange = (newPage) => {
    if (newPage !== page) setPage(newPage);
    fetchMore({
      variables: {
        offset: (pageSize * newPage),
        limit: pageSize,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return { ...prev, applicationCreativeConcepts: { ...prev.applicationCreativeConcepts, ...fetchMoreResult.applicationCreativeConcepts } };
      },
    });
  };

  const onPageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
  };

  const onSearchTermEntered = (searchItem) => {
    if (searchTerm !== searchItem) setSearchTerm(searchItem);
  };

  const onSearchClear = () => {
    setSearchTerm('');
  };

  useEffect(() => {
    if (selectedValue && selectionModel?.length === 0) setSelectionModel([selectedValue]);
  }, [selectedValue]);

  const columns = useMemo(() => {
    const columns = [
      {
        headerName: t('columns.image'),
        field: 'conceptImage',
        filterable: false,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        flex: 0.2,
        minWidth: 130,
        renderCell: (props) => {
          const { conceptImage } = props.row;
          const src = conceptImage !== null ? conceptImage : '';
          return (
            src === '' ? (
              <Icon
                icon={Icons.faPhotoVideo}
                size="3x"
              />
            ) : (
              <Box
                sx={{
                  height: '50px',
                  overflow: 'hidden',
                }}
              >
                <Box
                  component="img"
                  height={50}
                  src={src}
                />
              </Box>
            )
          );
        },
      },
      {
        headerName: t('columns.conceptName'),
        field: 'name',
        filterable: false,
        flex: 0.4,
        valueGetter: ({ row }) => (selectedValue === row?.code ? `* ${row?.name}` : row?.name),
      },
      {
        headerName: tg('status.name'),
        field: 'status',
        filterable: false,
        width: 210,
        valueGetter: ({ row }) => tcg(`creativeStatus.${row?.status}`),
      },
    ];
    return columns;
  }, [t]);

  return (
    <Grid
      container
      spacing={2}
    >
      <Grid
        item
        xs={12}
        sx={{
          flex: '1 1 auto',
        }}
      >
        <Grid
          container
          sx={{
            height: '100%',
          }}
        >
          <Grid
            item
            xs={12}
            sx={error ? {
              border: '1px red solid',
              height: '100%',
            } : { height: '100%' }}
          >
            <Grid
              container
              pb={2}
              alignItems="center"
            >
              <Grid
                item
                xs={12}
              >
                <Search
                  noPadding
                  width="100%"
                  searchTerm={searchTerm}
                  onSearchTermEntered={(searchItem) => onSearchTermEntered(searchItem)}
                  label={t('search')}
                  placeholder={t('searchItems')}
                  onClear={onSearchClear}
                />
              </Grid>
            </Grid>
            <DataGrid
              columns={columns}
              rows={gridData}
              parentContainerProps={{
                height: 400,
                padding: '0px',
              }}
              loading={loading}
              pagination
              paginationMode="server"
              page={page}
              pageSize={pageSize}
              onPageChange={onPageChange}
              onPageSizeChange={onPageSizeChange}
              rowsPerPageOptions={[25, 50, 100]}
              rowCount={rowCount}
              sort={[]}
              onSelectionModelChange={(newRowSelectionModel) => {
                setSelectionModel(newRowSelectionModel);
                onSelect(newRowSelectionModel);
              }}
              selectionModel={selectionModel}
              disableMultipleSelection
              keepNonExistentRowsSelected
              localeText={{
                noResultsOverlayLabel: talt('dataGrid.localeText.conceptSearch'),
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      {error ? (
        <FormHelperText
          error
          required
          variant="filled"
        >
          {error}
        </FormHelperText>
      ) : null}
    </Grid>
  );
};
