import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import UploadImage from './UploadImage/UploadImage';
import AssetInput from './AssetInput';

function SingelUploadInput({
  placeholder,
  onUpload,
  disabled = false,
  style = {},
  maxFiles,
  accept,
  file,
  fileActions = [],
  fileType,
  large = false,
  onRemove,
}) {
  const { t: tg } = useTranslation('general');

  if (placeholder === undefined) placeholder = tg('chooseFile');

  const onDrop = useCallback(
    (acceptedFiles) => {
      const filteredAndFormatedFiles = acceptedFiles
        .map((file) => ({
          preview: URL.createObjectURL(file),
          file,
        }));
      if (filteredAndFormatedFiles.length) onUpload(filteredAndFormatedFiles);
    },
    [onUpload],
  );

  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
    open,
  } = useDropzone({
    multiple: false,
    accept,
    onDrop,
    maxFiles,
    noClick: file,
    disabled,
  });

  const inputStyle = useMemo(() => ({
    width: '100%',
    height: '100%',
    ...(isDragAccept ? { borderColor: '#00e676' } : {}),
    ...(isDragReject ? { borderColor: 'red' } : {}),
    ...style,
  }), [isDragAccept, isDragReject, style]);

  const checkFileType = useMemo(() => {
    if (file?.file?.type) {
      if (file?.file?.type?.includes('pdf')) return 'pdf';
      if (file?.file?.type?.includes('image')) return 'image';
      if (file?.file?.type?.includes('video')) return 'video';
      return 'other';
    }
    if (!file?.file?.type && file?.file) return 'other';

    if (fileType?.code === 'pdf') return 'pdf';
    if (fileType?.type === 'image') return 'image';
    if (fileType?.type === 'video') return 'video';
    if (file.includes('.pdf')) return 'pdf';
    return 'other';
  }, [file, fileType]);

  const actions = !disabled
    ? fileActions?.map((action) => {
      if (action.type === 'openFileChooser') {
        action = { ...action, onClick: (e) => { e.preventDefault(); open(); } };
      }
      if (action.type === 'remove') {
        action = { ...action, onClick: () => onRemove() };
      }
      return action;
    })
    : [];

  const fileUrl = file ? (typeof file === 'string') ? file : file.preview : '';
  return (
    <>
      <AssetInput
        title={!file && placeholder}
        rootProps={getRootProps()}
        disabled={disabled}
        style={inputStyle}
      >
        {file ? (
          <UploadImage
            image={checkFileType === 'image' || checkFileType === 'video' ? fileUrl : null}
            fileType={checkFileType}
            large={large}
            actions={actions}
          />
        ) : null}
        {!disabled && <input {...getInputProps()} />}
      </AssetInput>
    </>
  );
}

export default SingelUploadInput;
