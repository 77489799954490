import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import {
  Select as MuiSelect,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  Checkbox,
  Button,
  ListItemText,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const UncheckedCheckboxIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const CheckedCheckboxIcon = <CheckBoxIcon fontSize="small" />;

export default function Select({
  label,
  name,
  options = [],
  labelKey = 'label',
  valueKey = 'value',
  value,
  disabled,
  readOnly,
  onChange,
  variant = 'filled',
  error,
  errorText,
  checkbox = false,
  allOption = false,
  renderValue: customRenderValue,
  onlyOption = false,
  ...rest
}) {
  const { t } = useTranslation('ui');
  const onlyOptionRef = useRef(null);
  const createOptions = (item) => (
    <MenuItem
      key={item[labelKey]}
      value={item[valueKey]}
    >

      {rest.multiple && checkbox && (
        <Checkbox
          icon={UncheckedCheckboxIcon}
          checkedIcon={CheckedCheckboxIcon}
          style={{ marginRight: 8, padding: '5px' }}
          checked={(value || []).includes(item[valueKey])}
        />
      )}
      <ListItemText
        sx={onlyOption ? {
          marginTop: '0px',
          marginBottom: '0px',
          span: {
            lineHeight: 'normal',
          },
          marginRight: '8px',
        } : {
          marginTop: '0px',
          marginBottom: '0px',
          span: {
            lineHeight: 'normal',
          },
        }}
      >
        {item[labelKey]}
      </ListItemText>
      {onlyOption && item[valueKey] !== 'all' && (
        <Button
          variant="contained"
          sx={{
            padding: '0px',
          }}
          onClick={() => {
            onlyOptionRef.current = item;
          }}
        >
          {t('select.only')}
        </Button>
      )}
    </MenuItem>
  );

  if (rest.multiple && (!value || !value.length)) value = rest.defaultValue || [];
  const renderValue = useMemo(() => {
    if (!rest.multiple) return null;
    if (customRenderValue) return customRenderValue;
    return (selected) => options
      .filter((option) => selected.includes(option[valueKey]))
      .map((option) => option[labelKey])
      .join(', ');
  }, [customRenderValue, rest.multiple]);

  const optionsToRender = useMemo(() => {
    if (!rest.multiple || !allOption) return options;
    return [{ [labelKey]: 'All', [valueKey]: 'all' }, ...options];
  }, [options, allOption]);

  const onChangeInternal = useCallback((event) => {
    if (onlyOption && onlyOptionRef.current !== null) {
      event.target.value = [onlyOptionRef.current[valueKey]];
      onChange(event);
      onlyOptionRef.current = null;
      return;
    }

    if (!rest.multiple || !allOption) {
      onChange(event);
      return;
    }

    // all selected
    if (!value.includes('all') && event.target.value.includes('all')) {
      event.target.value = ['all', ...options.map((option) => option[valueKey])];
      onChange(event);
      return;
    }

    // all unselected
    if (value.includes('all') && !event.target.value.includes('all')) {
      event.target.value = [];
      onChange(event);
      return;
    }

    // something else selected
    if (value.includes('all')
      && event.target.value.filter((val) => val !== 'all').length < options.length
    ) {
      event.target.value = event.target.value.filter((val) => val !== 'all');
      onChange(event);
      return;
    }
    // everything is selected but all
    if (!value.includes('all')
      && event.target.value.filter((val) => val !== 'all').length === options.length
    ) {
      event.target.value = ['all', ...options.map((option) => option[valueKey])];
      onChange(event);
      return;
    }
    onChange(event);
  }, [options, value]);

  useEffect(() => {
    if (!rest.multiple || !allOption) return;
    if (value.includes('all') && value.length !== (options.length + 1)) {
      onChange({ target: { value: ['all', ...options.map((option) => option[valueKey])] } });
    }
  }, [value, options]);

  return (
    <FormControl
      fullWidth
      error={error}
    >
      <InputLabel id={name + label}>{label}</InputLabel>
      <MuiSelect
        labelId={name + label}
        label={label}
        value={value}
        id={name}
        fullWidth
        variant={variant}
        disabled={disabled}
        readOnly={readOnly}
        onChange={onChangeInternal}
        renderValue={renderValue}
        {...rest}
      >
        {optionsToRender.map((item) => createOptions(item)) }
      </MuiSelect>
      {error ? (
        <FormHelperText>{errorText}</FormHelperText>
      ) : null}
    </FormControl>
  );
}
